import React from 'react'

import Fab from '@material-ui/core/Fab'
import Modal from '@material-ui/core/Modal'

import CloseIcon from '@material-ui/icons/Close'

import { makeStyles } from '@material-ui/core/styles'

import { blackOff } from '../styles/theme'

const useStyles = makeStyles(({ spacing, breakpoints: { down } }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  image: {
    maxHeight: '90vh',
    maxWidth: '90vw',
    [down('sm')]: {
      maxHeight: '100vh',
      maxWidth: '100vw',
    },
    borderRadius: 4,
    boxShadow:
      '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
    backgroundColor: blackOff,
  },

  close: {
    position: 'fixed',
    top: spacing(2),
    right: spacing(2),
    [down('sm')]: {
      top: spacing(),
      right: spacing(),
    },
    backgroundColor: 'white',
  },

  closeIcon: {
    height: 32,
    width: 32,
  },
}))

const ImageModal: React.FC<{
  open?: boolean
  src?: string
  close: () => void
}> = ({ open, src, close }) => {
  const classes = useStyles()

  return (
    <Modal open={!!open} onClose={close} className={classes.root}>
      <>
        <img alt="attachment" src={src} className={classes.image} />

        <Fab onClick={close} className={classes.close}>
          <CloseIcon className={classes.closeIcon} />
        </Fab>
      </>
    </Modal>
  )
}

export default ImageModal

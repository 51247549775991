import React, { useState, useCallback } from 'react'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import TextField from '@material-ui/core/TextField'

import { makeStyles } from '@material-ui/core/styles'

import ArrowIcon from '@material-ui/icons/ArrowDownward'

import Lottie from 'react-lottie-player'

import animation from '../assets/animation.json'

import { recaptchaSiteKey } from '../config'

import { blackOff } from '../styles/theme'

import isValidEmail from '../utils/isValidEmail'

import Logo from '../components/Logo'
import Logo2 from '../components/Logo2'
import ImageModal from '../components/ImageModal'

const projects: {
  title: string
  body: string | string[]
  images: string[]
  links?: {
    label: string
    href: string
  }[]
}[] = [
  {
    title: 'Platform for primary care',
    body: [
      "The simplest and most obvious way to digitalize primary care is with video consulations. It's basically the same thing except the doctor can't do a physical examination or take tests. But except for the lack of travel, which admittedly is nice, we haven't really improved the experience for either party a whole lot. You're still locked to a specific time and duration.",
      'The next step is to make primary care asynchronous. Why limit ourselves to a 30 minute videochat? It seems much better to have the patient provide as much data as is needed upfront. If the case is not suitable for digital care then let the patient know right away and not waste everyones time. The doctor can then make an assessment in their own time, ask followup questions, request lab tests or consult colleagues. Lastly the patient can get a conclusion to their case, be it a diagnosis, a prescription or good advice.',
      "In this project we built an asynchronous primary care platform. It includes triaging, messaging, videochat, prescriptions, referrals, diagnostics, decision support. Most systems become a bloated mess once you've included all the bells, whistles and seatbelts required to provide great digital primary care. We worked to avoid that pitfall by modularizing as much as possible and mostly sticking to a simple event log with strict revision history.",
    ],
    images: ['caregiver', 'patient'],
  },
  {
    title: 'Triage engine',
    body: [
      'The holy grail in digitalizing primary care is to automate the triaging. If you opt for machine learning or similar approaches you end up with a black box of probabilities that will be very difficult to audit and validate. Another approach is to compose complex flows out of fairly simple programmatic logic.',
      "There you could go with a standard form builder approach using JSON or XML. But you'll quickly notice that it's tricky to express even simple logic or dependencies between questions. A less limiting approach is to use a Domain Specific Language (DSL). This gives you all the flexibility a programmer has when writing code while still maintaing strict rules on what, where and how things may be referenced.",
      "In this project we built such a triaging engine using a DSL. It supports any number of ways to query a patient. It's also fully asynchronous so a patient can pause midway through and finish at a later time. The idea is to build small reusable well-tested modules that dive deep into a specific topic like alcohol habits, diabetes or heart disease. You can then use these modules to compose a general-purpose triaging flow.",
    ],
    images: ['triage', 'triage2'],
    links: [
      {
        label: 'Triage demo with dummy questions',
        href: 'https://triage.filmusli.com',
      },
    ],
  },
  {
    title: 'IT system for advanced home care',
    body: [
      'Home care is on the rise. But much of healthcare technology is geared towards care in a hospital. In this project we teamed up with doctors and nurses working with advanced home care, typically cancer patients and often palliative in nature.',
      "While we built a whole lot of things, what turned out to be the most useful was a simple planning system. In hindsight it's fairly obvious that the main differentiator to hospital care is the daily planning of who goes where and does what.",
      'Other things we built include a general purpose WebRTC videochat, a remote monitoring system for patients with kidney disease and a journaling system for end-of-life care.',
    ],
    images: ['house'],
    links: [
      {
        label:
          'Dagens Medicin - Guldskalpellen: Bidrag från Region Skåne (Swedish)',
        href:
          'https://www.dagensmedicin.se/alla-nyheter/nyheter/guldskalpellen-bidrag-fran-region-skane',
      },
      {
        label: 'Vårdfokus - "Utan systemet skulle jag gå hem" (Swedish)',
        href:
          'https://www.vardfokus.se/nyheter/utan-systemet-skulle-jag-ga-hem',
      },
    ],
  },
  {
    title: 'Rehabilitation app for stroke patients',
    body: [
      'Aphasia is an inability to comprehend or formulate language. It is commonly caused by a stroke. The rehabilitation process is long and arduous. In this project we built a rehabilitation app based on a few key insights.',
      'The first is that training on just a few hundred words can unlock countless more. Especially if the words already carry significance to the patient.',
      'Secondly that the learning process is greatly improved if you can trigger an emotional response. This can be done by playing short video clips that include moments of suspense, joy or any number of emotions.',
      'Lastly we had the idea to supercharge all of this with community generated content. The patients friends and family know best what words carry significance and can record short video clips that are particularly meaningful. Even better if they are open to sharing what they created with other patients going through the same struggles.',
    ],
    images: ['parrot'],
  },
  {
    title: 'Messaging system for surgical wards',
    body: [
      "There's few places in the world more high-tech than your typical surgical ward. So you might find it surprising how crude the means of communication can be. When a need arises inside the operating room, someone presses a button on the wall and the sirens go off in the entire ward until someone pops their head in to ask what's up.",
      "While there's good reason for the sirens to go off when there's an emergency, most surgeries are routine and their needs less critical. After having set up shop at a surgical ward for a few months we built a simple messaging system where the staff inside the operating room could type out a quick request. We equipped the staff outside with cheap but reliable Android devices so they could quickly respond and indicte that they had grabbed the task.",
      'Everyone now knew what had been requested and who took care of it. And the sirens could be saved for when lives were actually at stake.',
    ],
    images: ['surgery'],
    links: [
      {
        label: 'Dagens Medicin - Ny idé gav tystare avdelning (Swedish)',
        href:
          'https://www.dagensmedicin.se/specialistomraden/kirurgi/ny-ide-gav-tystare-avdelning',
      },
    ],
  },
]

const useStyles = makeStyles(({ spacing, breakpoints: { down, up } }) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },

  home: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    overflow: 'hidden',
  },

  projects: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundColor: blackOff,
    color: 'white',
  },

  projectsInner: {
    maxWidth: 1200 + spacing(4),
    margin: '0 auto',
  },

  about: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundColor: `${blackOff}22`,
  },

  aboutInner: {
    maxWidth: 600,
    margin: '0 auto',
  },

  contact: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundColor: 'white',
  },

  contactInner: {
    maxWidth: 600,
    margin: '0 auto',
  },

  background: {
    position: 'absolute',
    top: '50vh',
    bottom: -2000,
    left: -2000,
    right: -2000,
    transform: 'rotate(-30deg)',
    backgroundColor: blackOff,
    [down('lg')]: {
      top: '40vh',
    },
    [down('md')]: {
      top: '30vh',
    },
    [down('sm')]: {
      top: 'min(360px, 50vh)',
    },
  },

  top: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },

  logo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 56,
    margin: `${spacing(3)}px ${spacing(3)}px`,
    [down('sm')]: {
      height: 40,
      margin: `${spacing(1.5)}px ${spacing()}px`,
    },
  },

  logoIcon: {
    height: 56,
    marginRight: spacing(1.25),
    [down('sm')]: {
      height: 40,
      marginRight: spacing(0.25),
    },
  },

  logoText: {
    height: 28,
    [down('sm')]: {
      height: 20,
    },
  },

  menuDesktop: {
    display: 'flex',
    flexDirection: 'row',
    margin: spacing(2),
    [down('sm')]: {
      display: 'none',
    },
  },

  menuMobile: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    marginTop: spacing(4),
    [up('md')]: {
      display: 'none',
    },
  },

  menuItem: {
    color: 'white',
    fontSize: '1rem',
    paddingLeft: spacing(2),
    paddingRight: spacing(2),
    marginRight: spacing(1),
    marginLeft: spacing(1),
  },

  middle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: `${spacing(3)}px ${spacing(3)}px`,
    [down('sm')]: {
      margin: `${spacing(3)}px ${spacing(2)}px`,
    },
  },

  bottom: {},

  tagline: {
    zIndex: 10,
    textAlign: 'center',
    color: 'white',
    [up('md')]: {
      fontSize: '1.25rem',
    },
  },

  animation: {
    height: 'max(70vh, 300px)',
    width: '100%',
    [down('sm')]: {
      height: 'max(50vh, 300px)',
    },
  },

  arrow: {
    position: 'absolute',
    bottom: spacing(2),
    right: spacing(2),
    color: 'white',
    [down('sm')]: {
      display: 'none',
    },
  },

  arrowIcon: {
    height: 40,
    width: 40,
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    margin: `${spacing(8)}px ${spacing(3)}px`,
    [down('sm')]: {
      margin: `${spacing(6)}px ${spacing(2)}px`,
    },
  },

  project: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginBottom: spacing(8),
    [down('sm')]: {
      flexDirection: 'column',
      '&:nth-child(odd)': {
        flexDirection: 'column-reverse',
      },
      marginBottom: spacing(4),
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },

  left: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    [up('md')]: {
      marginRight: spacing(2),
    },
    [down('sm')]: {
      alignItems: 'flex-start',
      marginBottom: spacing(2),
    },
  },

  leftWithImages: {
    alignItems: 'flex-end',
    [down('sm')]: {
      alignItems: 'flex-start',
    },
  },

  right: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    [up('md')]: {
      marginLeft: spacing(2),
    },
    [down('sm')]: {
      marginBottom: spacing(2),
    },
  },

  title: {
    color: 'inherit',
    marginBottom: spacing(4),
    '&:last-child': {
      marginBottom: 0,
    },
  },

  subtitle: {
    color: 'inherit',
    marginBottom: spacing(2),
    '&:last-child': {
      marginBottom: 0,
    },
  },

  paragraph: {
    color: 'inherit',
    marginBottom: spacing(2),
    '&:last-child': {
      marginBottom: 0,
    },
  },

  images: {
    display: 'flex',
    flexDirection: 'row',
    [down('lg')]: {
      flexDirection: 'column',
    },
    [down('xs')]: {
      marginLeft: -spacing(2),
      marginRight: -spacing(2),
      width: `calc(100% + ${spacing(4)}px)`,
    },
  },

  image: {
    maxHeight: 280,
    '&:only-child': {
      maxHeight: 300,
    },
    objectFit: 'cover',
    maxWidth: '100%',
    borderRadius: 4,
    cursor: 'pointer',
    marginRight: spacing(2),
    '&:last-child': {
      marginRight: 0,
    },
    [down('lg')]: {
      maxHeight: 300,
      marginRight: 0,
      '&:not(:first-child)': {
        display: 'none',
      },
    },
    [down('xs')]: {
      maxHeight: 'auto',
      borderRadius: 0,
      pointerEvents: 'none',
    },
  },

  linkWrapper: {
    marginBottom: spacing(1),
    '&:last-child': {
      marginBottom: 0,
    },
  },

  link: {
    color: 'rgb(75,203,232)',
  },

  center: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  me: {
    height: 200,
    width: 200,
    marginBottom: spacing(4),
    borderRadius: 100,
  },

  input: {
    marginBottom: spacing(3),
    '&:last-child': {
      marginBottom: 0,
    },
  },

  contactIcon: {
    height: 200,
    width: 200,
    marginBottom: spacing(4),
  },
}))

declare global {
  interface Window {
    grecaptcha: any
    firebase: any
  }
}

const initialState = {
  submitting: false,
  succeeded: false,
  failed: false,
  body: '',
  email: '',
}

const Index: React.FC = () => {
  const classes = useStyles()

  const [modal, setModal] = useState<string | undefined>()

  const menuItems = (
    <>
      <Button href="#projects" className={classes.menuItem}>
        Projects
      </Button>

      <Button href="#about" className={classes.menuItem}>
        About
      </Button>

      <Button href="#contact" className={classes.menuItem}>
        Contact
      </Button>
    </>
  )

  const [{ submitting, succeeded, failed, body, email }, setState] = useState(
    initialState
  )

  const submit = useCallback(async () => {
    if (!body.trim() || !isValidEmail(email)) {
      return
    }

    setState((state) => ({
      ...state,
      submitting: true,
      succeeded: false,
      failed: false,
    }))

    try {
      await new Promise<void>((resolve, reject) => {
        const {
          grecaptcha,
          firebase: { initializeApp, firestore },
        } = window

        initializeApp({
          apiKey: 'AIzaSyB7xRxMnUhMaMlA8HaSEl-EDz68U_-s1z8',
          authDomain: 'filmusli.firebaseapp.com',
          projectId: 'filmusli',
          storageBucket: 'filmusli.appspot.com',
          messagingSenderId: '1044436963171',
          appId: '1:1044436963171:web:7f3a61b4a3552313f24d56',
        })

        grecaptcha.ready(function () {
          grecaptcha
            .execute(recaptchaSiteKey, { action: 'submit' })
            .then(() =>
              firestore()
                .collection('messages')
                .add({
                  email,
                  body,
                  created: firestore.FieldValue.serverTimestamp(),
                })
                .then(resolve)
                .catch(reject)
            )
            .catch(reject)
        })
      })
    } catch (e) {
      console.error(e)
      setState((state) => ({
        ...state,
        submitting: false,
        succeeded: false,
        failed: true,
      }))
      return
    }

    setState({
      ...initialState,
      submitting: false,
      succeeded: true,
      failed: false,
    })
  }, [body, email])

  return (
    <>
      <Box className={classes.root}>
        <Box id="home" className={classes.home}>
          <div className={classes.background} />

          <Box className={classes.top}>
            <div className={classes.logo}>
              <Logo2 className={classes.logoIcon} />

              <Logo className={classes.logoText} />
            </div>

            <div className={classes.menuDesktop}>{menuItems}</div>
          </Box>

          <Box className={classes.middle}>
            <Lottie
              loop
              animationData={animation}
              play
              className={classes.animation}
            />

            <Typography className={classes.tagline}>
              {`I'll build you a secret base on the dark side of the moon, no questions asked.`}
            </Typography>

            <div className={classes.menuMobile}>{menuItems}</div>
          </Box>

          <Box className={classes.bottom}></Box>

          <IconButton href="#projects" className={classes.arrow}>
            <ArrowIcon className={classes.arrowIcon} />
          </IconButton>
        </Box>

        <Box id="projects" className={classes.projects}>
          <Box className={classes.projectsInner}>
            <Box className={classes.content}>
              <Typography variant="h2" className={classes.title}>
                Projects
              </Typography>

              {projects.map(({ title, body, links, images }, i) => {
                const even = i % 2 === 0

                const text = (
                  <>
                    <Typography
                      variant="h4"
                      component="h3"
                      className={classes.subtitle}
                    >
                      {title}
                    </Typography>

                    {(Array.isArray(body) ? body : [body]).map((b, i) => (
                      <Typography key={i} className={classes.paragraph}>
                        {b}
                      </Typography>
                    ))}

                    {links?.map((link) => (
                      <div key={link.href} className={classes.linkWrapper}>
                        <Link href={link.href} className={classes.link}>
                          {link.label}
                        </Link>
                      </div>
                    ))}
                  </>
                )

                const art = images.length ? (
                  <Box className={classes.images}>
                    {images.map((name, j) => {
                      const key = `${i}.${j}`

                      return (
                        <img
                          key={key}
                          src={`/${name}_small.png`}
                          className={classes.image}
                          onClick={() => setModal(key)}
                        />
                      )
                    })}
                  </Box>
                ) : null

                const modals = images.map((name, j) => {
                  const key = `${i}.${j}`

                  return (
                    <ImageModal
                      key={key}
                      open={modal === key}
                      src={`/${name}.png`}
                      close={() => setModal(undefined)}
                    />
                  )
                })

                return (
                  <Box key={i} className={classes.project}>
                    <Box
                      className={`${classes.left} ${
                        even ? '' : classes.leftWithImages
                      }`}
                    >
                      {even ? text : art}
                    </Box>

                    <Box className={classes.right}>{even ? art : text}</Box>

                    {modals}
                  </Box>
                )
              })}
            </Box>
          </Box>
        </Box>

        <Box id="about" className={classes.about}>
          <Box className={classes.aboutInner}>
            <Box className={classes.content}>
              <Typography variant="h2" className={classes.title}>
                About
              </Typography>

              <Box className={classes.center}>
                <img src="/me.png" className={classes.me} />
              </Box>

              <Typography className={classes.paragraph}>
                {`My name is Erik Johansson and I'm a Software Engineer.`}
              </Typography>

              <Typography className={classes.paragraph}>
                {`I've done all kinds of work but healthcare is my bread and
                butter. In some ways I've already had my dream job. For a few years I got to run
                around hospital wards pestering people about ideas, theirs and
                mine. Some of them I then got to build. A few even made it into
                general use.`}
              </Typography>

              <Typography className={classes.paragraph}>
                {`But the wheels of progress are slow in the public sector.
                Sometimes for good reason. Other times not so much. I got tired
                of watching great ideas go to waste. So now I try to contribute
                to whatever interesting projects come my way. If you have ideas,
                I'm all ears.`}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box id="contact" className={classes.contact}>
          <Box className={classes.contactInner}>
            <Box className={classes.content}>
              <Typography variant="h2" className={classes.title}>
                Contact
              </Typography>

              <Box className={classes.center}>
                <img src="/experience.svg" className={classes.contactIcon} />
              </Box>

              <Typography className={classes.paragraph}>
                {`Do you have an idea so crazy it just might work? Let me build it
              and we can find out together. I promise it'll be a lot quicker and
              cheaper to build a first version than you can ever imagine.`}
              </Typography>

              <TextField
                variant="outlined"
                multiline
                label="Message"
                placeholder="What, when and where"
                value={body}
                onChange={(event) =>
                  setState((state) => ({
                    ...state,
                    succeeded: false,
                    failed: false,
                    body: event.target.value,
                  }))
                }
                className={classes.input}
              />

              <TextField
                type="email"
                variant="outlined"
                label="Email"
                placeholder="name@mail.com"
                value={email}
                onChange={(event) =>
                  setState((state) => ({
                    ...state,
                    succeeded: false,
                    failed: false,
                    email: event.target.value,
                  }))
                }
                className={classes.input}
              />

              <Button
                color="primary"
                variant="contained"
                size="large"
                fullWidth
                disabled={submitting || !body.trim() || !isValidEmail(email)}
                onClick={submit}
                className={classes.input}
              >
                {submitting ? 'Sending...' : 'Send'}
              </Button>

              {succeeded && (
                <Typography
                  style={{ textAlign: 'center' }}
                >{`Thank you for reaching out! I'll be in touch as soon as possible.`}</Typography>
              )}

              {failed && (
                <Typography style={{ textAlign: 'center', color: '#E52427' }}>
                  Failed to send message
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default Index
